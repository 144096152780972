<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Organisations', route: 'Admin_Organisations' },
        { name: 'Create a Organisation', route: 'Admin_Organisations' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="CreateOrganisation">
            <label for="form.name">Organisation Name</label>
            <input type="text" class="input" v-model="form.name" required />
            <label for="form.address">Address</label>
            <textarea
              class="input left"
              v-model="form.address"
              rows="4"
              required
            ></textarea>
            <label for="form.postcode">Postcode</label>
            <input type="text" class="input" v-model="form.postcode" required />
            <label for="form.contact_email">Contact Email</label>
            <input
              type="email"
              class="input"
              v-model="form.contact_email"
              required
            />
            <label for="form.contact_telephone">Contact Telephone</label>
            <input
              type="tel"
              class="input"
              v-model="form.contact_telephone"
              required
            />
            <br />
            <br />
            <label for="form.owner">MANAGER Email Address</label>
            <input type="email" class="input" v-model="form.owner" required />
            <br />
            <br />
            <input type="submit" class="btn btn-green" value="Save Changes" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      messages: "",
      errors: "",
      form: {},
    };
  },
  components: {
    Navigation,
  },
  methods: {
    CreateOrganisation() {
      this.store
        .dispatch("Admin/CreateOrganisation", this.form)
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
          this.$router.push({
            name: "Admin_Organisations_Modify",
            params: { id: result.id },
          });
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>